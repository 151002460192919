import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Countries";

const MODULE_TITLE = t("list-of-countries");

const NAME = "countries";

const settings = {
  hasView: false,
  hasExport: false,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  rowsOptions: {
    hasView: false,
    hasBlock: false,
    hasDelete: false,
    hasEdit: true,
  },
};

const tableHeader: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("country-name-ar"),
    value: "CountryNameAr",
  },
  {
    label: t("country-name-en"),
    value: "CountryNameEn",
  },
  {
    label: t("no-of-cities"),
    value: "noOfCities",
  },
  {
    label: t("created-on"),
    value: "createdAt",
  },
  {
    label: t("is-default"),
    value: "isDefault",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
};
