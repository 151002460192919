import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import importCities from "./importCities";

const route: RouteRecordRaw = {
  path: "/import-cities/",
  name: "ImportCities",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("ImportCities"), routeName: "ImportCities" }],
  },
  children: [
    {
      path: "import-cities",
      name: "ImportCities",
      component: () =>
        import("@/modules/ImportCities/screens/ImportCities.vue"),
      meta: {
        breadcrumb: [
          { title: t("ImportCities"), routeName: "ImportCities" },
          { title: t("ImportCities"), routeName: "ImportCities" },
        ],
      },
    },
    {
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route, importCities };
