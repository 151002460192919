import { RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

export const routes: RouteRecordRaw = {
  path: "/vouchers-v2",
  name: "vouchers-v2",
  redirect: { name: "DistributorManagement" },
  children: [
    {
      path: "/vouchers-v2/distributors",
      name: "DistributorManagement",
      component: () => import("@/modules/Vouchers-v2/screens/Distributors.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("distributors"), routeName: "DistributorManagement" },
        ],
      },
    },
    {
      path: "distributors/create",
      name: "CreateDistributor",
      component: () =>
        import("@/modules/Vouchers-v2/screens/DistributorForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("distributors"), routeName: "DistributorManagement" },
          { title: t("create-distributor"), routeName: "CreateDistributor" },
        ],
      },
    },
    {
      path: "distributors/:id",
      name: "UpdateDistributor",
      component: () =>
        import("@/modules/Vouchers-v2/screens/DistributorForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("distributors"), routeName: "DistributorManagement" },
          { title: t("update-distributor"), routeName: "UpdateDistributor" },
        ],
      },
    },
    {
      path: "/vouchers-v2/batches",
      name: "VoucherBatchManagement",
      component: () =>
        import("@/modules/Vouchers-v2/screens/VoucherBatches.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("batches"), routeName: "VoucherBatchManagement" },
        ],
      },
    },
    {
      path: "batches/create",
      name: "CreateVoucherBatch",
      component: () =>
        import("@/modules/Vouchers-v2/screens/VoucherBatchForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("batches"), routeName: "VoucherBatchManagement" },
          { title: t("create-batch"), routeName: "CreateVoucherBatch" },
        ],
      },
    },
    {
      path: "batches/:id",
      name: "UpdateVoucherBatch",
      component: () =>
        import("@/modules/Vouchers-v2/screens/VoucherBatchForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("batches"), routeName: "VoucherBatchManagement" },
          { title: t("update-batch"), routeName: "UpdateVoucherBatch" },
        ],
      },
    },
    {
      path: "/vouchers-v2/subBatches/:batchId",
      name: "SubBatchManagement",
      component: () => import("@/modules/Vouchers-v2/screens/SubBatches.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("batches"), routeName: "VoucherBatchManagement" },
          { title: t("sub-batches"), routeName: "SubBatchManagement" },
        ],
      },
    },
    {
      path: "subBatches/:batchId/create",
      name: "CreateSubBatch",
      component: () => import("@/modules/Vouchers-v2/screens/SubBatchForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers"), routeName: "vouchers-v2" },
          { title: t("batches"), routeName: "VoucherBatchManagement" },
          { title: t("sub-batches"), routeName: "SubBatchManagement" },
          { title: t("create-sub-batch"), routeName: "CreateSubBatch" },
        ],
      },
    },
    {
      path: "/vouchers-v2/exports",
      name: "VoucherExports",
      component: () =>
        import("@/modules/Vouchers-v2/screens/VoucherExports.vue"),
      meta: {
        breadcrumb: [
          { title: t("batches"), routeName: "VoucherBatchManagement" },
          { title: t("exports"), routeName: "VoucherExports" },
        ],
      },
    },
  ],
};
