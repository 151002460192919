import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import importCountries from "./importCountries";

const route: RouteRecordRaw = {
  path: "/import-countries/",
  name: "ImportCountries",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("ImportCountries"), routeName: "ImportCountries" }],
  },
  children: [
    {
      path: "import-countries",
      name: "ImportCountries",
      component: () =>
        import("@/modules/ImportCountries/screens/ImportCountries.vue"),
      meta: {
        breadcrumb: [
          { title: t("ImportCountries"), routeName: "ImportCountries" },
          { title: t("ImportCountries"), routeName: "ImportCountries" },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "ImportCountries",
    },
    {
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route, importCountries };
