import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import importSchools from "./importSchools";

const route: RouteRecordRaw = {
  path: "/import-schools/",
  name: "ImportSchools",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("ImportSchools"), routeName: "ImportSchools" }],
  },
  children: [
    {
      path: "import-schools",
      name: "ImportSchools",
      component: () =>
        import("@/modules/ImportSchools/screens/ImportSchools.vue"),
      meta: {
        breadcrumb: [
          { title: t("ImportSchools"), routeName: "ImportSchools" },
          { title: t("ImportSchools"), routeName: "ImportSchools" },
        ],
      },
    },
    {
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route, importSchools };
