import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Imports/countries";

const MODULE_TITLE = t("import-countries");

const NAME = "ImportCountries";

export default {
  API_URL,
  NAME,
  MODULE_TITLE,
};
