import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Imports/cities";

const MODULE_TITLE = t("import-cities");

const NAME = "ImportCities";

export default {
  API_URL,
  NAME,
  MODULE_TITLE,
};
