<template>
  <div v-bind="$attrs" class="flex items-center justify-between pb-5">
    <div class="flex items-center gap-3 whitespace-nowrap">
      <h1 class="text-2xl font-normal text-primary">
        {{ title }}
      </h1>
      <h2
        class="flex items-center justify-center px-5 py-2 font-normal align-middle bg-white rounded-3xl text-dark800"
      >
        {{ sub_title }}
      </h2>
    </div>
    <div class="flex items-center gap-3">
      <button
        v-if="button_placeholder"
        @click="onActionButtonClicked"
        class="gap-3 !rounded-lg button-primary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            id="add_FILL0_wght200_GRAD0_opsz48"
            d="M20.423,31V21.577H11V20.423h9.423V11h1.154v9.423H31v1.154H21.577V31Z"
            transform="translate(-11 -11)"
            fill="#fff"
          />
        </svg>
      </button>
      <slot name="extra-actions"> </slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
defineProps({
  button_placeholder: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  sub_title: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["actionClicked"]);

const onActionButtonClicked = () => {
  emit("actionClicked");
};
</script>
